import React from "react";
import EmailIcon from "../../assets/images/toolbar-icons/messages.png";
import QuestionMarkIcon from "../../assets/images/toolbar-icons/question-mark.png";
import styled from "styled-components";
import { Product, User } from "src/types";
import { useAppSelector } from "src/hooks";
import { CustomMenu } from "src/uikit";
import { Avatar } from "@mui/material";
import SCSS_VARIABLES from "src/styles/component.scss";
import { Logout, Person, Email, Settings, School } from "@mui/icons-material";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { useDispatch } from "react-redux";
import StratusLogo from "../../assets/images/stratus-logo.svg";
import FeatureFlags from "src/utils/FeatureFlags";

interface RightContentProps {}

const RightContentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    & .section {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
    }

    & .stratus-logo {
        align-items: center;
        display: flex;
        width: 140px;
    }

    .menu {
        max-width: 50px;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const RightContent: React.FC<RightContentProps> = (props) => {
    const dispatch = useDispatch();

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value
    );

    const user: User | undefined = useAppSelector((state) => state?.Self.value);

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product?.productName ?? ""
        ]?.dropdownStateView;
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const showMenu = Boolean(anchorEl);

    const showAvatar = false;

    const handleCompanyOptionsClick = () => {
        if (!product) return;

        dispatch(
            GlobalStateActions[product?.productName]?.setCompanyOptionsOpen(
                true
            )
        );
    };

    const handleEmailClick = () => {
        if (product?.productName === "Agent")
            window.location.href = process.env.REACT_APP_AGENT_EMAIL!;
        else window.location.href = process.env.REACT_APP_STRATUS_EMAIL!;
    };

    const handleSupportClick = () => {
        if (product?.productName === "Agent")
            window.open(process.env.REACT_APP_AGENT_HELP!, "_blank");
        else window.open(process.env.REACT_APP_STRATUS_HELP!, "_blank");
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <RightContentWrapper>
                {!dropdownStateView &&   // Hide FormsPlus settings Icon on 11/07/2024
                    ["Premium", "Municipal"].includes(
                        product?.productName ?? ""
                    ) && (
                        <div
                            className="section"
                            onClick={handleCompanyOptionsClick}
                            data-testid="company-options"
                        >
                            <Settings
                                className="icon"
                                data-testid="SettingsIcon"
                                height={24}
                            />
                        </div>
                    )}
                <div
                    className="section"
                    onClick={handleEmailClick}
                    data-testid="email"
                >
                    <img
                        className="icon"
                        src={EmailIcon}
                        alt="Email Icon"
                        data-testid="EmailIcon"
                        height={24}
                    />
                </div>
                <div className="section" onClick={handleSupportClick}>
                    <img
                        className="icon"
                        src={QuestionMarkIcon}
                        alt="Question Mark Icon"
                        data-testid="HelpIcon"
                        height={24}
                    />
                </div>
                <div className="stratus-logo">
                    <img
                        className="icon"
                        src={StratusLogo}
                        alt="Stratus Icon"
                        data-testid="stratus-icon"
                    />
                </div>
                {showAvatar && (
                    <div className="section menu" onClick={handleOpenUserMenu}>
                        <Avatar
                            alt={user?.firstName}
                            sx={{
                                backgroundColor: SCSS_VARIABLES.primary,
                                color: "white",
                            }}
                        ></Avatar>
                        {user?.firstName}
                    </div>
                )}
            </RightContentWrapper>
            <CustomMenu
                onClose={() => {
                    setAnchorEl(null);
                }}
                open={showMenu}
                anchorEl={anchorEl}
                items={[
                    {
                        icon: <Person />,
                        text: "Profile",
                        cb: () => {
                            console.log("Navigate to Profile Page");
                        },
                    },
                    {
                        icon: <Settings />,
                        text: "Settings",
                        cb: () => {
                            console.log("Navigate to Settings Page");
                        },
                    },
                    {
                        icon: <School />,
                        text: "Documentation",
                        cb: () => {
                            console.log("Navigate to Documentation Page");
                        },
                    },
                    {
                        icon: <Email />,
                        text: "Contact Support",
                        cb: () => {
                            console.log("Open Email Link to Contact Support");
                        },
                    },
                    {
                        icon: <Logout />,
                        text: "Logout",
                        cb: () => {
                            console.log("Logout");
                        },
                    },
                ]}
            />
        </>
    );
};

export default RightContent;
