import { CompanyOptionsEntity } from "src/types/CompanyOptionsService.types";
import { BaseAPIService } from "./BaseApiService";

export class CompanyOptionsService {
    private static _instance: CompanyOptionsService;
    private readonly BASE_PATH: string = "/api/return-company/options";
    private api: BaseAPIService = BaseAPIService.getInstance();

    private getPath(): string {
        return this.BASE_PATH;
    }

    getCompanyOptions(companyId: number): Promise<CompanyOptionsEntity> {
        return this.api.get<CompanyOptionsEntity>(
            this.getPath() + `?companyId=${companyId}`
        );
    }

    setCompanyOptions(
        options: CompanyOptionsEntity
    ): Promise<CompanyOptionsEntity> {
        return this.api.post<CompanyOptionsEntity>(this.getPath(), options);
    }

    public static getInstance(): CompanyOptionsService {
        if (!CompanyOptionsService._instance) {
            CompanyOptionsService._instance = new CompanyOptionsService();
        }
        return CompanyOptionsService._instance;
    }
}
